import React from "react";
import { Text, Title } from "components";
import lang from "translations";
import { LampIcon, MobilePushVenueDataImg, MobileUpdatedVersionImg } from "images";
import { ArrowRightOutlined } from "@ant-design/icons";

const StepUpdate = () => {
  return (
    <>
      <div className="flex gap-4 md:gap-6 items-center">
        <div className="w-8 h-8 min-w-8 min-h-8 md:w-16 md:h-16 md:min-w-16 md:min-h-16">
          <img src={LampIcon} alt="" />
        </div>
        <div>
          <Title>{lang.guidelineDownloadOrUpdateApp}</Title>
          <Text size="text-md" color="text-pelorous-darker" fontWeight="font-bold">
            {lang.beforeUpdatingTheAppPleaseEnsureAllDataIsSyncedBy}
          </Text>
        </div>
      </div>
      <div className="flex mt-3 md:mx-16 gap-4">
        <div className="flex flex-col justify-center items-center">
          <div className="rounded-full px-3 py-2 bg-green-400 text-white w-fit">{lang.step} 1</div>
          <Text
            className="my-4 md:whitespace-nowrap"
            size="text-sm md:text-md"
            color="text-black-darker"
          >
            {lang.goToVenueTabAndTapOnPushVenueData}
          </Text>
          <img src={MobilePushVenueDataImg} alt="" className="w-56" />
        </div>
        <div className="flex-1 flex justify-center items-center">
          <ArrowRightOutlined className={"arrow-right"} />
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="rounded-full px-3 py-2 bg-green-400 text-white w-fit">{lang.step} 2</div>
          <Text
            className="my-4 md:whitespace-nowrap"
            size="text-sm md:text-md"
            color="text-black-darker"
          >
            {lang.goToVenueTabAndTapOnPushVenueData}
          </Text>
          <img src={MobileUpdatedVersionImg} alt="" className="w-56" />
        </div>
      </div>
    </>
  );
};

export default StepUpdate;
