import React from "react";
import lang from "translations";
import { Icon, Modal, Text } from "components";
import { Path } from "paths";
import Notes from "./notes";
import "./styles.scss";
import StepUpdate from "./step-update";

const UpdateVersionModal = ({
  versionName = "",
  playStoreLink = "",
  apkS3Link = "",
  description = "",
  ...props
}) => {
  const dataNote = { versionName, playStoreLink, apkS3Link, description };
  return (
    <Modal {...props} noHeader width={1200} closable>
      <div className="md:px-20 pt-4 relative">
        <Icon
          className="absolute right-0 top-4 outline-none text-xl text-gray hover:text-gray cursor-pointer"
          name="remove"
          onClick={() => {
            props.close();
          }}
        />
        <Notes {...dataNote} />
        <Guideline close={props.close} />
      </div>
    </Modal>
  );
};

export default UpdateVersionModal;

const Guideline = ({ close }) => {
  return (
    <>
      <section className="mt-8 bg-green-100 rounded-lg px-6 py-3">
        <StepUpdate />
        <Text
          className="pt-3 pb-8"
          size="text-md"
          color="text-pelorous-darker"
          fontWeight="font-bold"
        >
          {lang.toViewDetailedInstructions},{" "}
          <span>
            <a href={Path.DEVICE_NOTES_GUIDE} className="underline font-bold hover:underline">
              {lang.clickHere}
            </a>
          </span>
        </Text>
      </section>
      <Text className="pb-10" size="text-md" color="text-pelorous-darker" fontWeight="font-bold">
        {lang.noteUpdateLastVersion}
        <span className="underline font-bold cursor-pointer hover:text-blue-400" onClick={close}>
          {lang.clickHere}
        </span>
      </Text>
    </>
  );
};
