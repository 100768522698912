import React, { useCallback, useMemo, useContext } from "react";
import { Field, Filter, MultipleSelect, RangePicker, Text, TimePicker } from "components/commons";
import { useApi, useMount } from "hooks";
import { VenueContext } from "contexts";
import { searchBalanceMovementsVenues } from "apis/multi-property.api";
import lang from "translations";

const MpBalanceMovementOutFilter = ({
  filterState,
  requestState,
  modifyFilter,
  clearFilter,
  applyFilter,
}) => {
  const { venue } = useContext(VenueContext);

  const {
    request: balanceMovementsVenuesRequest,
    loading: balanceMovementsVenuesLoading,
    result: balanceMovementsVenuesResult = { data: [] },
  } = useApi({
    api: searchBalanceMovementsVenues,
    isArray: true,
    handleOwnError: true,
  });

  useMount(async () => {
    await balanceMovementsVenuesRequest({ venueId: venue.venueId });
  });

  const venues = useMemo(() => {
    const { data: venues } = balanceMovementsVenuesResult || {};
    return venues.sort().map((venue) => {
      return { text: venue.venueName, value: venue.venueId };
    });
  }, [balanceMovementsVenuesResult]);

  const changeDateRangeCb = useCallback(
    (name, value) => {
      modifyFilter(name, { value });
    },
    [modifyFilter]
  );

  const clearFilterCb = useCallback(() => {
    clearFilter();
  }, [clearFilter]);

  return (
    <Filter
      placeholder={lang.searchGuestsBalanceMovements}
      className="my-lg"
      filterState={filterState}
      onApply={applyFilter}
      onClear={clearFilterCb}
      actionsSpan={12}
    >
      <Field filterLabel={lang.date} className="col-span-4">
        <RangePicker name="dateRange" value={filterState.dateRange} onChange={changeDateRangeCb} />
      </Field>
      <Field filterLabel={lang.timeRange} className="col-span-4">
        <div className="flex items-center">
          <TimePicker
            value={filterState.dateRange[0]}
            onChange={(startTime) => {
              modifyFilter("dateRange", {
                value: [
                  filterState.dateRange[0].set({
                    hour: startTime.get("hour"),
                    minute: startTime.get("minute"),
                  }),
                  filterState.dateRange[1],
                ],
              });
            }}
          />
          <Text className="mx-sm" color="text-black-light">
            {lang.to}
          </Text>
          <TimePicker
            value={filterState.dateRange[1]}
            onChange={(endTime) => {
              modifyFilter("dateRange", {
                value: [
                  filterState.dateRange[0],
                  filterState.dateRange[1].set({
                    hour: endTime.get("hour"),
                    minute: endTime.get("minute"),
                  }),
                ],
              });
            }}
          />
        </div>
      </Field>
      {true && (
        <Field filterLabel={lang.venue} className="col-span-4">
          <MultipleSelect
            name="venueList"
            defaultAll={true}
            selectAllText={lang.allVenues}
            options={venues}
            isAll={filterState.venueList && filterState.venueList.length === 0}
            value={filterState.venueList}
            onChange={(name, obj) => {
              modifyFilter(name, { value: obj.value });
            }}
            placeholder={lang.selectVenue}
            optionsLoading={balanceMovementsVenuesLoading}
          />
        </Field>
      )}
    </Filter>
  );
};

export default MpBalanceMovementOutFilter;
