import React, { useCallback } from "react";
import { Field, Filter, MultipleSelect, RangePicker, Text, TimePicker } from "components/commons";
import { statusFilter } from "./filters";
import lang from "translations";

const BookingFilter = ({ filterState, requestState, modifyFilter, clearFilter, applyFilter }) => {
  const changeDateRangeCb = useCallback(
    (name, value) => {
      modifyFilter(name, { value });
    },
    [modifyFilter]
  );

  const clearFilterCb = useCallback(() => {
    clearFilter();
  }, [clearFilter]);

  return (
    <Filter
      placeholder={lang.search2}
      className="my-lg"
      filterState={filterState}
      onApply={applyFilter}
      onClear={clearFilterCb}
      actionsSpan={12}
    >
      <Field filterLabel={lang.date} className="col-span-4">
        <RangePicker name="dateRange" value={filterState.dateRange} onChange={changeDateRangeCb} />
      </Field>
      <Field filterLabel={lang.timeRange} className="col-span-4">
        <div className="flex items-center">
          <TimePicker
            value={filterState.dateRange[0]}
            onChange={(startTime) => {
              modifyFilter("dateRange", {
                value: [
                  filterState.dateRange[0].set({
                    hour: startTime.get("hour"),
                    minute: startTime.get("minute"),
                  }),
                  filterState.dateRange[1],
                ],
              });
            }}
          />
          <Text className="mx-sm" color="text-black-light">
            {lang.to}
          </Text>
          <TimePicker
            value={filterState.dateRange[1]}
            onChange={(endTime) => {
              modifyFilter("dateRange", {
                value: [
                  filterState.dateRange[0],
                  filterState.dateRange[1].set({
                    hour: endTime.get("hour"),
                    minute: endTime.get("minute"),
                  }),
                ],
              });
            }}
          />
        </div>
      </Field>
      <Field filterLabel={lang.status} className="col-span-4">
        <MultipleSelect
          name="statusList"
          defaultAll={true}
          selectAllText={lang.all}
          options={statusFilter}
          isAll={filterState.statusList && filterState.statusList.length === 0}
          value={filterState.statusList}
          onChange={(name, obj) => {
            modifyFilter(name, { value: obj.value });
          }}
          placeholder={lang.selectStatus}
        />
      </Field>
    </Filter>
  );
};

export default BookingFilter;
