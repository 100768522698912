import {
  DiscountStatus,
  StaffRole,
  StaffStatus,
  TransactionType,
  VoucherStatus,
  ProductType,
  VoucherType,
  GuestStatus,
  TransactionStatus,
  TapStatus,
  Field,
  BanType,
  ReceiptName,
  InventoryMovementType,
  ProductStockLevel,
  ProductCreationType,
  BookingStatus,
  StocktakeStatus,
  TimeUnit,
  AppCategory,
  AccountName,
  AccountType,
  IntegrationType,
  BookingSource,
  EMode,
  ExternalPaymentStatus,
} from "enums";
import lang from "translations";

export const prettifyProductStockLevel = (stockLevel) => {
  return (
    {
      [ProductStockLevel.InStock]: lang.inStock,
      [ProductStockLevel.CheckStock]: lang.checkStocks,
      [ProductStockLevel.ReorderStock]: lang.reorderStocks,
      [ProductStockLevel.NoStock]: lang.noStocks,
    }[stockLevel] || ""
  );
};

export const prettifyPaymentMethod = (method, dynamicPaymentMethod) => {
  if (dynamicPaymentMethod) {
    return dynamicPaymentMethod.paymentMethodName;
  }

  return (
    {
      OTHER: lang.others,
      CREDIT: lang.credit,
      CREDIT_DEBIT: lang.creditDebit,
      CARD: lang.card,
      CASH: lang.cash,
      POUCH_PAY: lang.pouchPay,
    }[method] || method
  );
};

export const prettifyStaffRole = (staffRole) => {
  return (
    {
      [StaffRole.SellOnPos]: lang.sellOnPos,
      [StaffRole.TopupCredits]: lang.topupAndReturnCredits,
      [StaffRole.RegisterGuests]: lang.registerGuests,
      [StaffRole.Manager]: lang.manager,
      [StaffRole.ReturnCredits]: lang.returnCredits,
      [StaffRole.StockManager]: lang.stockManager,
      [StaffRole.FrontOffice]: lang.frontOffice,
      [StaffRole.BackOffice]: lang.backOffice,
      [StaffRole.AccessControl]: lang.accessControl,
    }[staffRole] || ""
  );
};

export const classDashboardAccess = (staffRole) => {
  return (
    {
      [StaffRole.SellOnPos]: "text-neutral-900 text-sm",
      [StaffRole.TopupCredits]: "text-neutral-900 text-sm",
      [StaffRole.RegisterGuests]: "text-neutral-900 text-sm",
      [StaffRole.Manager]: lang.manager,
      [StaffRole.ReturnCredits]: "text-neutral-900 text-sm",
      [StaffRole.StockManager]: "text-neutral-900 text-sm",
      [StaffRole.FrontOffice]: "text-neutral-900 text-sm",
      [StaffRole.BackOffice]: "text-neutral-900 text-sm",
      [StaffRole.AccessControl]: "text-neutral-900 text-sm",
    }[staffRole] || ""
  );
};

export const describeDashboardAccess = (staffRole) => {
  return (
    {
      [StaffRole.SellOnPos]: lang.sellOnPosRoleDashboardDesc,
      [StaffRole.TopupCredits]: lang.topupCreditsRoleDashboardDesc,
      [StaffRole.RegisterGuests]: lang.registerGuestsRoleDashboardDesc,
      [StaffRole.Manager]: lang.manager,
      [StaffRole.ReturnCredits]: lang.returnCreditsRoleDashboardDesc,
      [StaffRole.StockManager]: lang.stockManagerRoleDashboardDesc,
      [StaffRole.FrontOffice]: lang.frontOfficeRoleDashboardDesc,
      [StaffRole.BackOffice]: lang.backOfficeRoleDashboardDesc,
      [StaffRole.AccessControl]: lang.accessControlRoleDashboardDesc,
    }[staffRole] || ""
  );
};

export const classAppAccess = (staffRole) => {
  return (
    {
      [StaffRole.SellOnPos]: "text-gray text-sm",
      [StaffRole.TopupCredits]: "text-gray text-sm",
      [StaffRole.RegisterGuests]: "text-gray text-sm",
      [StaffRole.Manager]: lang.manager,
      [StaffRole.ReturnCredits]: "text-gray text-sm",
      [StaffRole.StockManager]: "text-gray text-sm",
      [StaffRole.FrontOffice]: "text-gray text-sm",
      [StaffRole.BackOffice]: "text-gray text-sm",
      [StaffRole.AccessControl]: "text-gray text-sm",
    }[staffRole] || ""
  );
};

export const describeAppAccess = (staffRole) => {
  return (
    {
      [StaffRole.SellOnPos]: lang.sellOnPosRoleAppDesc,
      [StaffRole.TopupCredits]: lang.topupCreditsRoleAppDesc,
      [StaffRole.RegisterGuests]: lang.registerGuestsRoleAppDesc,
      [StaffRole.Manager]: lang.manager,
      [StaffRole.ReturnCredits]: lang.returnCreditsRoleAppDesc,
      [StaffRole.StockManager]: lang.stockManagerRoleAppDesc,
      [StaffRole.FrontOffice]: lang.frontOfficeRoleAppDesc,
      [StaffRole.BackOffice]: lang.backOfficeRoleAppDesc,
      [StaffRole.AccessControl]: lang.accessControlRoleAppDesc,
    }[staffRole] || ""
  );
};

export const prettifyStaffStatus = (staffStatus) => {
  return (
    {
      [StaffStatus.Awaiting]: lang.awaitingInviteResponse,
      [StaffStatus.Inactive]: lang.inactive,
    }[staffStatus] || ""
  );
};

export const prettifyDiscountStatus = (discountStatus) => {
  return (
    {
      [DiscountStatus.Active]: lang.active,
      [DiscountStatus.Inactive]: lang.inactive,
      [DiscountStatus.Expired]: lang.expired,
    }[discountStatus] || ""
  );
};

export const prettifyVoucherStatus = (voucherStatus) => {
  return (
    {
      [VoucherStatus.Active]: lang.active,
      [VoucherStatus.Inactive]: lang.inactive,
      [VoucherStatus.Expired]: lang.expired,
    }[voucherStatus] || ""
  );
};

export const prettifyTransactionType = (transactionType) => {
  return (
    {
      [TransactionType.TOPUP]: lang.topupCredits,
      [TransactionType.SALE]: lang.sale,
      [TransactionType.RETURN]: lang.returnCredits,
      [TransactionType.MIXED]: lang.mixed,
      [TransactionType.REDEEM]: lang.redeem,
      [TransactionType.REMOVE]: lang.remove,
      [TransactionType.ISSUE_FOC]: lang.issueFreeCredits,
      [TransactionType.REMOVE_FOC]: lang.removeFreeCredits,
      [TransactionType.ISSUE]: lang.issue,
      [TransactionType.VOUCHER_ISSUE]: lang.voucherIssue,
      [TransactionType.VOUCHER_REMOVE]: lang.voucherRemove,
    }[transactionType] || ""
  );
};

export const prettifyProductType = (productType, count) => {
  const isPlural = count > 1;
  return (
    {
      [ProductType.Standard]: lang.standard,
      [ProductType.Variant]: isPlural ? lang.variants : lang.variant,
      [ProductType.Composite]: lang.composite,
      [ProductType.SupplyItem]: lang.supplyItem,
    }[productType] || ""
  );
};

export const prettifyProductCreationType = (productCreationType, count) => {
  return (
    {
      [ProductCreationType.Whole]: lang.whole,
      [ProductCreationType.ProductSet]: lang.productSet,
      [ProductCreationType.Assemble]: lang.assemble,
    }[productCreationType] || ""
  );
};

export const prettifyVoucherType = (voucherType) => {
  return (
    {
      [VoucherType.Issue]: lang.issue,
      [VoucherType.Redeem]: lang.redeem,
      [VoucherType.Remove]: lang.remove,
      [VoucherType.Usage]: lang.usage,
    }[voucherType] || ""
  );
};

export const prettifyGuestStatus = (guestStatus) => {
  return (
    {
      [GuestStatus.CHECKED_IN]: lang.checkedIn,
      [GuestStatus.CHECKED_OUT]: lang.checkedOut,
    }[guestStatus] || ""
  );
};

export const prettifyTransactionStatus = (transactionStatus) => {
  return (
    {
      [TransactionStatus.SUCCESS]: lang.success,
      [TransactionStatus.VOID]: lang.voided,
    }[transactionStatus] || ""
  );
};

export const prettifyTapStatus = (tapStatus) => {
  return (
    {
      [TapStatus.Success]: lang.success,
      [TapStatus.Failed]: lang.failed,
    }[tapStatus] || ""
  );
};

export const prettifyExPaymentStatus = (status) => {
  return (
    {
      [ExternalPaymentStatus.Success]: lang.success,
      [ExternalPaymentStatus.Failed]: lang.failed,
      [ExternalPaymentStatus.Pending]: lang.pending,
      [ExternalPaymentStatus.Timeout]: lang.timeout,
    }[status] || ""
  );
};

export const prettifyFieldType = (type) => {
  return (
    {
      [Field.INPUT]: lang.textbox,
      [Field.DROPDOWN]: lang.dropdown,
      [Field.CHECKBOX]: lang.checkbox,
      [Field.RADIO]: lang.radioButton,
      [Field.COUNTRY]: lang.country,
      [Field.NATIONALITY]: lang.nationality,
    }[type] || lang.textbox
  );
};

export const prettifyBanType = (type) => {
  return (
    {
      [BanType.LOST_TAG]: lang.lostTag,
      [BanType.DEFECTIVE_TAG]: lang.defectiveTag,
      [BanType.OTHERS]: lang.others,
    }[type] || ""
  );
};

export const prettifyReceiptOptions = (receiptName) => {
  return (
    {
      [ReceiptName.VenueLogo]: lang.venueLogo,
      [ReceiptName.VenueName]: lang.venueName,
      [ReceiptName.BusinessName]: lang.businessName,
      [ReceiptName.BusinessAddress]: lang.businessAddress,
      [ReceiptName.BusinessContactNumber]: lang.businessContactNumber,
      [ReceiptName.TaxNumber]: lang.taxNumber,
      [ReceiptName.DeviceId]: lang.deviceId,
      [ReceiptName.SerialNumber]: lang.serialNumber,
      [ReceiptName.ReceiptNumber]: lang.receiptNumber,
      [ReceiptName.DateAndTimeOfTransaction]: lang.dateAndTimeOfTransaction,
      [ReceiptName.VenueLocation]: lang.venueLocation,
      [ReceiptName.StaffNameAndOrTagId]: lang.staffNameAndOrTagId,
      [ReceiptName.TaxInvoiceNumber]: lang.taxInvoiceNumber,
      [ReceiptName.PosProvider]: lang.posProvider,
      [ReceiptName.GuestNameAndOrTagId]: lang.guestNameAndOrTagId,
      [ReceiptName.TransactionId]: lang.transactionId,
      [ReceiptName.ItemTax]: lang.itemTax,
      [ReceiptName.GuestsPreviousBalanceTotalPaidAndNewBalance]:
        lang.guestsPreviousBalanceTotalPaidAndNewBalance,
      [ReceiptName.CustomHeader]: lang.customLabel,
      [ReceiptName.CustomFooter]: lang.customLabel,
    }[receiptName] || ""
  );
};

export const prettifyInventoryMovementType = (movementType) => {
  return (
    {
      [InventoryMovementType.AddedStock]: lang.addedStock,
      [InventoryMovementType.RemovedStock]: lang.removedStock,
      [InventoryMovementType.CreatedSupplyItem]: lang.createdSupplyItem,
      [InventoryMovementType.TrackedInventory]: lang.trackedInventory,
      [InventoryMovementType.Sale]: lang.sale,
      [InventoryMovementType.SaleReturn]: lang.saleReturn,
      [InventoryMovementType.Mixed]: lang.mixed,
      [InventoryMovementType.Redeemed]: lang.redeemed,
    }[movementType] || ""
  );
};

export const prettifyIntegrationType = (integrationType) => {
  return (
    {
      [IntegrationType.MEWS]: lang.mews,
      [IntegrationType.MEWS_BOOKINGS]: lang.mewsBookings1,
      [IntegrationType.MEWS_ACCOUNTING]: lang.mewsAccounting1,
      [IntegrationType.CLOUDBEDS]: lang.cloudbeds,
      [IntegrationType.PURCHASE_PLUS]: lang.purchaseplus,
      [IntegrationType.OTHERS]: lang.others,
    }[integrationType] || integrationType
  );
};

export const prettifyBookingStatus = (status) => {
  return (
    {
      [BookingStatus.TO_BE_CHECKED_IN]: lang.toBeCheckedIn,
      [BookingStatus.TO_BE_CHECKED_OUT]: lang.toBeCheckedOut,
      [BookingStatus.CONFIRMED]: lang.confirmed,
      [BookingStatus.FUTURE]: lang.future,
      [BookingStatus.IN_PROGRESS]: lang.inProgress,
      [BookingStatus.CHECKED_IN]: lang.checkedIn,
      [BookingStatus.MISSED_CHECK_IN]: lang.missedCheckIn,
      [BookingStatus.CHECKED_OUT]: lang.checkedOut,
      [BookingStatus.MISSED_CHECK_OUT]: lang.missedCheckOut,
      [BookingStatus.CANCELLED]: lang.cancelled,
      [BookingStatus.OTHERS]: lang.others,
      [BookingStatus.NO_SHOW]: lang.noShow,
      [BookingStatus.CONFIRMATION_PENDING]: lang.confirmationPending,
      [BookingStatus.CANCELED]: lang.cancelled,
      [BookingStatus.IN_HOUSE]: lang.inHouse,
      [BookingStatus.DELETED]: lang.deleted,
    }[status] || ""
  );
};

export const prettifyStocktakeStatus = (status) => {
  return (
    {
      [StocktakeStatus.InProgress]: lang.inProgress,
      [StocktakeStatus.ForApproval]: lang.forApproval,
      [StocktakeStatus.Completed]: lang.completed,
      [StocktakeStatus.Void]: lang.void,
    }[status] || ""
  );
};

export const normalizeBookingStatus = (src) => {
  if (src?.source === IntegrationType.CLOUDBEDS) {
    if (src?.rawStatus === BookingStatus.NOT_CONFIRMED) {
      return BookingStatus.CONFIRMATION_PENDING;
    }
    if (src?.rawStatus === BookingStatus.CHECKED_IN) {
      return BookingStatus.IN_HOUSE;
    }
    return src?.rawStatus;
  } else {
    if (src?.rawStatus === BookingStatus.CONFIRMED) {
      return BookingStatus.FUTURE;
    }
    return src?.rawStatus;
  }
};

export const prettifyTimeUnit = (timeValue, timeUnit) => {
  return timeValue === 1
    ? {
        [TimeUnit.Second]: lang.second,
        [TimeUnit.Minute]: lang.minute,
        [TimeUnit.Hour]: lang.hour,
        [TimeUnit.Day]: lang.day,
        [TimeUnit.Week]: lang.weekMon2Sun,
        [TimeUnit.WeekS]: lang.weekSun2Sat,
        [TimeUnit.Month]: lang.month,
        [TimeUnit.Quarter]: lang.quarter,
        [TimeUnit.Year]: lang.year,
      }[timeUnit] || ""
    : {
        [TimeUnit.Second]: lang.seconds,
        [TimeUnit.Minute]: lang.minutes,
        [TimeUnit.Hour]: lang.hours,
        [TimeUnit.Day]: lang.days,
        [TimeUnit.Month]: lang.months,
        [TimeUnit.Year]: lang.years,
      }[timeUnit] || "";
};

export const prettifyIntervalTime = (timeUnit) => {
  return (
    {
      [TimeUnit.Hour]: lang.hourly,
      [TimeUnit.Day]: lang.daily,
      [TimeUnit.Week]: lang.weekMon2Sun,
      [TimeUnit.WeekS]: lang.weekSun2Sat,
      [TimeUnit.Month]: lang.monthly,
      [TimeUnit.Quarter]: lang.quarterly,
    }[timeUnit] || ""
  );
};

export const prettifyApplicationCategory = (category) => {
  return (
    {
      [AppCategory.AccountingSoftware]: lang.accountingSoftware,
      [AppCategory.Distribution]: lang.distribution,
      [AppCategory.InventoryManagement]: lang.inventoryManagement,
      [AppCategory.PointOfSale]: lang.pointOfSale,
      [AppCategory.PropertyManagementSystem]: lang.propertyManagementSystem,
      [AppCategory.Others]: lang.others,
    }[category] || ""
  );
};
export const prettifyAccountingName = (accountingName) => {
  return (
    {
      [AccountName.InventoryAccount]: lang.inventoryAccount,
      [AccountName.InventoryGainLossAccount]: lang.inventoryGainLossAccount,
      [AccountName.TaxOnPurchaseAccount]: lang.taxOnPurchasesAccount,
      [AccountName.RevenueAccount]: lang.pointOfSale,
      [AccountName.CostOfGoodsSold]: lang.costOfGoodsSold,
      [AccountName.TaxOnSalesLiabilityAccount]: lang.taxOnSalesLiabilityAccount,
      [AccountName.SaleDiscounts]: lang.saleDiscounts,
      [AccountName.SaleFoc]: lang.saleFoc,
      [AccountName.CreditsLiabilityAccount]: lang.creditsLiabilityAccount,
      [AccountName.FreeCreditsLiabilityAccount]: lang.freeCreditsLiabilityAccount,
      [AccountName.PostpaidCreditsLiabilityAccount]: lang.postpaidCreditsLiabilityAccount,
      [AccountName.ClearedUnusedExpiredCredit]: lang.clearedUnusedExpiredCredit,
    }[accountingName] || ""
  );
};

export const prettifyAccountingType = (accountType) => {
  return (
    {
      [AccountType.Asset]: lang.asset,
      [AccountType.Liability]: lang.liability,
      [AccountType.Equity]: lang.equity,
      [AccountType.Revenue]: lang.revenue,
      [AccountType.Expense]: lang.expense,
      [AccountType.Unknown]: lang.unknown,
    }[accountType] || ""
  );
};

export const prettifyBookingSource = (source) => {
  return (
    {
      [BookingSource.MEWS]: lang.mews,
      [BookingSource.CLOUDBEDS]: lang.cloudbeds,
      [BookingSource.TICKETING]: lang.ticketing,
    }[source] || source
  );
};

export const prettifyMode = (source) => {
  return (
    {
      [EMode.Venue]: lang.venueMode,
      [EMode.Event]: lang.eventMode,
    }[source] || source
  );
};
