import React from "react";
import lang from "translations";
import { Text, Title } from "components";
import QrBox from "./qr-box";
import { DangerIcon, MobileVersionImg } from "images";
import useMobile from "hooks/useMobile";

const Notes = ({ versionName = "", playStoreLink = "", apkS3Link = "", description = "" }) => {
  const isMobile = useMobile();

  return (
    <div>
      <Text className={"mb-4"} color="text-pelorous" size="text-20">
        {lang.updatedVersionOfTheAppIsNowAvailable}
      </Text>
      <section className="pt-0">
        <Text>{lang.populate(lang.theLastVersionOfAppAvailable, [versionName])}</Text>
        <Text>{lang.theUpdateShouldBeAppliedAutomatically}</Text>
      </section>
      <section className="mt-10 flex border-b border-pelorous border-dashed mb-4 pb-4">
        <img src={MobileVersionImg} alt="" style={isMobile ? { height: 200 } : {}} />
        <div className="md:ml-4">
          <QrBox title={lang.pleaseCopyUrl} url={playStoreLink} />
          <QrBox title={lang.pleaseCopyUrlApk} url={apkS3Link} />
        </div>
      </section>
      <section>
        <Title color="text-black-dark">{lang.changelog}</Title>
        <div className="text-base whitespace-pre-wrap">{description}</div>
      </section>
      <section className="mt-8 bg-red-100 flex gap-4 md:gap-6 items-center rounded-lg px-6 py-3">
        <div className="w-8 h-8 min-w-8 min-h-8 md:w-16 md:h-16 md:min-w-16 md:min-h-16">
          <img src={DangerIcon} alt="" />
        </div>
        <div className="flex-1">
          <Title color="text-red">{lang.disclaimer}</Title>
          <div className="text-base whitespace-pre-wrap">{lang.disclaimerDesc}</div>
        </div>
      </section>
    </div>
  );
};

export default Notes;
