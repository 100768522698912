import React from "react";
import QRCode from "react-qr-code";
import lang from "translations";
import { Text, Toast } from "components";
import { SendIcon } from "images";
import { CopyOutlined } from "@ant-design/icons";

const QrBox = ({ title, url }) => {
  return (
    <div className="mb-4">
      <div className="flex items-center gap-4">
        <img src={SendIcon} alt="" />
        <Text className="" fontWeight="font-medium" size="text-md" color="text-black-dark">
          {title}
        </Text>
      </div>

      <div className="flex items-center flex-wrap gap-2 justify-end md:justify-start text-pelorous">
        <div className="border border-pelorous rounded-lg py-2 px-5 h-fit w-40 md:w-65 max-w-65 overflow-hidden whitespace-nowrap overflow-ellipsis">
          {url}
        </div>
        <div
          className="border border-pelorous rounded-lg py-2 px-5 h-fit ml-4 flex items-center cursor-pointer"
          onClick={() => {
            navigator.clipboard.writeText(url);
            Toast({
              content: lang.copied,
              success: true,
              icon: "check",
            }).open();
          }}
        >
          {lang.copy}
          <CopyOutlined className="ml-3" />
        </div>
        <div className="text-center md:px-16">
          <Text size="text-md">{lang.scanQRcode}</Text>
          <QRCode size={120} value={url} />
        </div>
      </div>
    </div>
  );
};

export default QrBox;
