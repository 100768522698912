import React, { useCallback, useMemo } from "react";
import {
  Text,
  Panel,
  Title,
  Select,
  Button,
  Pill,
  Skeleton,
  Dropdown,
  Icon,
  PageError,
  Field,
} from "components/commons";
import lang from "translations";
import { PillType } from "enums";
import style from "./guest-checkin-info.module.scss";
import { formatNumberToOrdinal, formatTagUIds as formatUid } from "services";
import ForceCheckoutModal from "components/modals/force-checkout-modal/force-checkout-modal";
import { useApi, useModal } from "hooks";
import { exportBillingStatement } from "apis/guest-checkin.api";
import classnames from "classnames";
import { getCurrentTimeWithTimezone } from "mappers/common.mapper";
import { useFlags } from "launchdarkly-react-client-sdk";

const GuestCheckinInfo = ({
  mappedData,
  selectedGuestCheckinId,
  setSelectedGuestCheckinId,
  mappedGuestCheckin,
  loading,
  onForceCheckout,
  error,
}) => {
  const { guestCheckinIds = [], firstName } = mappedData;
  const { guestViewCheckinAmount } = useFlags();
  const labels = [
    lang.totalSpent,
    lang.averageOrderValue,
    lang.lastPurchasedProduct,
    lang.lastVisitedLocation,
  ];

  const {
    checkedInBy,
    checkedOutBy,
    dateOfCheckin,
    dateOfCheckout,
    totalTagsIssued,
    totalSpent,
    useCountOfPairedTag,
    pairedTagOnCheckin,
    averageOrderValue,
    deviceName,
    lastPurchasedProduct,
    lastVisitedLocation,
    checkedIn = false,
    guestProfileName,
    deviceMacAddress,
    forceCheckOutReason,
    forceCheckedOutBy,
    lastSyncedDate,
    bookingNumber,
    checkinAmount,
  } = mappedGuestCheckin;

  const isCheckedIn =
    mappedGuestCheckin.dateOfCheckout === null ||
    mappedGuestCheckin.dateOfCheckout === "" ||
    mappedGuestCheckin.dateOfCheckout === "-";

  const forceCheckoutModal = useModal();

  const pairedTagUse = useCallback(() => {
    return (
      <div className="flex items-center justify-between">
        <Text>
          {pairedTagOnCheckin
            ? formatUid(pairedTagOnCheckin) + ` (${formatNumberToOrdinal(useCountOfPairedTag)} use)`
            : "-"}
        </Text>
      </div>
    );
  }, [pairedTagOnCheckin, useCountOfPairedTag]);

  const labelText = (label = "", text = "", customText = null, breakAll = false) => {
    return (
      <div className="row-span-1">
        <Field label={label}>
          {customText ? (
            customText
          ) : (
            <Text className={`mt-sm ${breakAll ? "break-all" : "break-words"} `}>{text}</Text>
          )}
        </Field>
      </div>
    );
  };

  const checkedInDetails = useMemo(() => {
    return (
      <>
        <div className="col-span-4 grid grid-rows-3 gap-4">
          {labelText(lang.bookingNo, bookingNumber)}
          {labelText(lang.dateOfCheckin, dateOfCheckin)}
          {labelText(lang.checkedInBy, checkedInBy, null, true)}
          {labelText(lang.totalTagsUsed, totalTagsIssued)}
        </div>
        <div className="col-span-4 grid grid-rows-3 gap-4">
          {guestViewCheckinAmount && checkinAmount && labelText(lang.bookingAmount, checkinAmount)}
          {labelText(lang.pairedTagOnCheckin, pairedTagUse())}
          {labelText(lang.deviceName, deviceName || deviceMacAddress, null, true)}
        </div>
      </>
    );
  }, [
    bookingNumber,
    dateOfCheckin,
    checkedInBy,
    totalTagsIssued,
    guestViewCheckinAmount,
    checkinAmount,
    pairedTagUse,
    deviceName,
    deviceMacAddress,
  ]);

  const checkedOutDetails = useMemo(() => {
    return (
      <>
        <div className="col-span-2 grid grid-rows-2 gap-4">
          {labelText(lang.bookingNo, bookingNumber)}
          {labelText(lang.dateOfCheckin, dateOfCheckin)}
          {labelText(lang.dateOfCheckout, dateOfCheckout)}
          {forceCheckedOutBy && labelText(lang.forceCheckOutBy, forceCheckedOutBy, "", true)}
        </div>
        <div className="col-span-2 grid grid-rows-2 gap-4">
          {labelText(lang.checkedInBy, checkedInBy, null, true)}
          {labelText(lang.checkedOutBy, checkedOutBy, null, true)}
          {forceCheckOutReason && labelText(lang.reason, forceCheckOutReason, "", true)}
        </div>
        <div className="col-span-2 grid grid-rows-2 gap-4">
          {labelText(lang.totalTagsUsed, totalTagsIssued)}
          {labelText(lang.pairedTagOnCheckin, pairedTagUse())}
        </div>
        <div className="col-span-2 grid grid-rows-2 gap-4">
          {/* <div className="row-span-1">{labelText(lang.wristbandKeeping, wristbandKeptTagUid)}</div> */}
        </div>
      </>
    );
  }, [
    bookingNumber,
    dateOfCheckin,
    dateOfCheckout,
    forceCheckedOutBy,
    checkedInBy,
    checkedOutBy,
    forceCheckOutReason,
    totalTagsIssued,
    pairedTagUse,
  ]);

  const checkInStatus = useMemo(() => {
    return (
      <div
        className={classnames(
          "h-60 w-25 bg-gradient-to-br rounded-md flex text-center items-center justify-center flex-col mb-md  px-sm",
          { [`${style.checkedInBg}`]: checkedIn, [`${style.checkedOutBg}`]: !checkedIn }
        )}
      >
        {checkedIn ? (
          <Pill className="ml-sm mb-sm" type={PillType.Greenish}>
            {lang.checkedIn}
          </Pill>
        ) : (
          <Pill className="ml-sm mb-sm" type={PillType.Gray}>
            {lang.checkedOut}
          </Pill>
        )}
        <Text
          size={guestProfileName?.length > 16 ? "text-lg" : "text-xxl"}
          className={"mb-sm font-bold text-black-light mx-md break-all"}
        >
          {guestProfileName}
        </Text>
      </div>
    );
  }, [guestProfileName, checkedIn]);

  const customSelectContent = guestCheckinIds.map((guestCheckinId) => {
    return {
      value: guestCheckinId.checkinId,
      text: guestCheckinId.formattedCheckinId,
    };
  });

  const getCheckinDropdownOptions = useMemo(() => {
    const forceCheckout = () => {
      forceCheckoutModal.show({
        title: lang.populate(lang.forceCheckoutName, [firstName || lang.guest]),
        primaryText: lang.checkout,
        initialState: undefined,
        isEdit: false,
        pairedTagOnCheckin,
      });
    };

    let options = [];
    if (isCheckedIn) {
      options.push({
        value: 0,
        text: <Text>{lang.forceCheckout}</Text>,
        onClick: () => {
          forceCheckout();
        },
      });
    }
    // options.push({
    //   value: 1,
    //   text: <Text>{lang.exportCheckinDetails}</Text>,
    //   onClick: () => {
    //     exportCheckinDetails();
    //   },
    // });

    return options;
  }, [forceCheckoutModal, firstName, isCheckedIn, pairedTagOnCheckin]);

  const { request: exportBillingStatementRequest, loading: exportBillingStatementLoading } = useApi(
    {
      api: exportBillingStatement,
    }
  );

  const onExportBillingStatement = useCallback(() => {
    let requestedAt = getCurrentTimeWithTimezone();
    exportBillingStatementRequest({ checkinId: selectedGuestCheckinId, requestedAt });
  }, [exportBillingStatementRequest, selectedGuestCheckinId]);

  if (error) {
    return <PageError />;
  }
  return loading ? (
    <Panel>
      <Skeleton />
    </Panel>
  ) : (
    <Panel>
      <div className="md:flex items-top justify-content">
        <div>{checkInStatus}</div>
        <div className="w-full sm:ml-md">
          <div className="flex items-top justify-between flex-wrap gap-2">
            <div className="w-full md:w-1/3">
              <Title className="pb-sm">{lang.checkinId}</Title>
              <Select
                options={customSelectContent}
                disabled={customSelectContent.length <= 1}
                onChange={(name, { value }) => {
                  setSelectedGuestCheckinId(value);
                }}
                value={selectedGuestCheckinId}
              ></Select>
            </div>
            <div className="flex-1 text-right">
              <div className={"flex items-center justify-end gap-1"}>
                <Button
                  onClick={() => {
                    onExportBillingStatement();
                  }}
                  loading={exportBillingStatementLoading}
                >
                  {lang.viewBillingStatement}
                </Button>
                <ForceCheckoutModal
                  checkinId={selectedGuestCheckinId}
                  {...forceCheckoutModal}
                  ok={onForceCheckout}
                />
                {getCheckinDropdownOptions.length ? (
                  <Dropdown
                    trigger="click"
                    placement="bottomRight"
                    options={getCheckinDropdownOptions}
                  >
                    <div className="px-sm py-sm flex items-center border rounded border-solid border-gray-200 cursor-pointer">
                      <Icon name="options" fontSize="4px" paddingless={true} className="py-2" />
                    </div>
                  </Dropdown>
                ) : null}
              </div>
              <div className="text-right text-gray-light mt-md">
                <Text label>
                  <lang.Translate text={lang.lastSynced} items={[lastSyncedDate]}></lang.Translate>
                </Text>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-8 gap-2 mt-lg items-start">
            {checkedIn ? checkedInDetails : checkedOutDetails}
          </div>
        </div>
      </div>
      <div className="-mr-lg -ml-lg mt-md border border-bottom border-gray-lightest"></div>
      <div className="grid grid-flow-col grid-cols-4 grid-rows-1 pt-sm gap-4 ">
        {labels.map((label, index) => {
          return (
            <div key={index}>
              <Text label className="mt-md font-bold">
                {label}
              </Text>
            </div>
          );
        })}
      </div>
      <div className="grid grid-flow-col grid-cols-4 grid-rows-1 pb-md gap-4 mt-sm">
        <>
          <Text className="font-medium text-md break-all">{totalSpent}</Text>
          <Text className="font-medium text-md break-all">{averageOrderValue}</Text>
          <Text className="font-medium text-md break-all">{lastPurchasedProduct}</Text>
          <Text className="font-medium text-md break-all">{lastVisitedLocation}</Text>
        </>
      </div>
    </Panel>
  );
};

export default GuestCheckinInfo;
