import React from "react";
import { Button, Dropdown } from "components";
import { CaretDownOutlined } from "@ant-design/icons";

const ButtonsGroup = ({
  label,
  options,
  disabled: primaryDisabled = false,
  loading: primaryLoading = false,
  handleClick,
  defaultSelect = 0,
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(defaultSelect);

  const handleMenuItemClick = (index) => {
    setSelectedIndex(index);
  };

  const onClick = () => {
    handleClick(selectedIndex);
  };

  return (
    <React.Fragment>
      <div className="flex items-center gap-px bg-pelorous-darker w-fit rounded">
        <Button
          loading={primaryLoading}
          disabled={primaryDisabled || primaryLoading}
          onClick={onClick}
          className={"rounded-r-none h-full"}
        >
          {options[selectedIndex].toUpperCase()}
        </Button>
        <Dropdown
          trigger="click"
          options={options.map((option, index) => ({
            text: option,
            onClick: () => handleMenuItemClick(index),
          }))}
        >
          <Button aria-label={label} aria-haspopup="menu" className={"rounded-l-none"}>
            <div className="h-5 flex items-center">
              <CaretDownOutlined />
            </div>
          </Button>
        </Dropdown>
      </div>
    </React.Fragment>
  );
};

export default ButtonsGroup;
