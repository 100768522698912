import { getSupplyItem } from "apis/supply-item.api";
import {
  Icon,
  SimpleDropdown,
  TabBar,
  Text,
  Title,
  ButtonLink,
  DataTable,
} from "components/commons";
import { HeaderB } from "components/headers";
import { StyleType } from "enums";
import { useApi, useFilter, useModal, useMount, useRouter } from "hooks";
import { Path } from "paths";
import React, { useCallback, useContext, useMemo } from "react";
import lang from "translations";
import { pluralize } from "services";
import { VenueContext } from "contexts";
import { searchProductMovement } from "apis/product.api";
import { formatDate } from "services";
import { DateTime } from "enums";
import { ModuleWrapper } from "components/fragments";
import SupplyItemMovementSkeleton from "./supply-item-movement-skeleton";
import { prettifyInventoryMovementType } from "services/pretty.service";
import { supplyItemSummary } from "mappers/supply-item.mapper";
import DeleteSupplyItem from "../delete-supply-item/delete-supply-item.module";
import { ArrowRightOutlined } from "@ant-design/icons";
import { mixpanel, TrackEvent } from "mixpanel";

const SupplyItemMovement = () => {
  const { history, query } = useRouter();
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const { id } = query;
  const deleteModal = useModal();
  const transactionalMovements = ["SALE", "MIXED", "REDEEMED", "SALE_RETURN"];
  const {
    request,
    mappedData: { name = "", measurement = "", description = "" },
    loading = true,
    error: errorSupplyItem,
  } = useApi({
    api: getSupplyItem,
    params: { id },
    handleOwnError: true,
    mapper: supplyItemSummary,
  });

  const {
    request: searchProduct,
    result,
    error,
  } = useApi({
    api: searchProductMovement,
    params: { id },
    handleOwnError: true,
  });

  const { filterState, requestState, modifyFilters } = useFilter({
    actions: [],
    page: 1,
    pageSize: 10,
    venueId,
  });

  useMount(() => {
    fetchSupplyItem();
    fetchSupplyItemMovement(requestState);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.inventoryMovementPage,
    });
  });

  const fetchSupplyItemMovement = useCallback(
    async (requestState) => {
      await searchProduct(requestState);
    },
    [searchProduct]
  );

  const fetchSupplyItem = useCallback(async () => {
    await request();
  }, [request]);

  const formatWithMeasurement = (value) => {
    return value + " " + measurement;
  };

  const renderSupplyItemMovement = useMemo(() => {
    return result
      ? result.data.map((supplyItem, index) => {
          const {
            movementId,
            actionDate,
            staffFirstName,
            staffLastName,
            productInventoryMovementActions,
          } = supplyItem;

          return {
            movementId: { movementId },
            date: formatDate(actionDate, DateTime.H),
            staff: <Text className="px-md">{staffFirstName + " " + staffLastName}</Text>,
            movement: ({ toggleRowItems, active, parent }) => {
              return (
                <div className="flex items-center">
                  <div className="pl-md py-xs w-60 md:w-96">
                    {transactionalMovements.includes(productInventoryMovementActions[0].action) ? (
                      <>
                        <ButtonLink
                          onClick={() => {
                            window.open(
                              Path.TRANSACTION_ID(
                                productInventoryMovementActions[0].transaction
                                  ? productInventoryMovementActions[0].transaction.transactionId
                                  : 0
                              )
                            );
                          }}
                        >
                          {prettifyInventoryMovementType(
                            productInventoryMovementActions[0].action
                          ) +
                            " #" +
                            (productInventoryMovementActions[0].transaction.transactionId ||
                              0)}{" "}
                          {/* <Icon fontSize="0.75rem" name="arrow-up-right2"></Icon> */}
                          <ArrowRightOutlined rotate={-45} className="ml-xs -mt-xs" />
                        </ButtonLink>
                      </>
                    ) : (
                      <Text className="text-sm">
                        {prettifyInventoryMovementType(productInventoryMovementActions[0].action)}
                      </Text>
                    )}
                    <Text label>{productInventoryMovementActions[0].reason}</Text>
                  </div>
                  {parent && productInventoryMovementActions.length > 1 ? (
                    <Icon
                      color="text-gray-lighter"
                      fontSize="lg"
                      name={active ? "arrow-down" : "arrow-right"}
                      onClick={toggleRowItems}
                    />
                  ) : null}
                </div>
              );
            },
            qty: (
              <Text className="pl-md flex items-center">
                {formatWithMeasurement(productInventoryMovementActions[0].quantity || 0)}
              </Text>
            ),
            location: (
              <>
                <Text className="px-md">{productInventoryMovementActions[0].locationName}</Text>
                <Text label className="flex items-center px-md">
                  {formatWithMeasurement(productInventoryMovementActions[0].oldValue || 0)}{" "}
                  <Icon name="arrow-right"></Icon>{" "}
                  {formatWithMeasurement(productInventoryMovementActions[0].newValue || 0)}
                </Text>
              </>
            ),
            _rowItems:
              index <= 0
                ? productInventoryMovementActions.slice(1).map((movement) => {
                    return {
                      movement: () =>
                        transactionalMovements.includes(movement.action) ? (
                          <ButtonLink
                            onClick={() => {
                              window.open(
                                Path.TRANSACTION_ID(
                                  movement.transaction ? movement.transaction.transactionId : 0
                                )
                              );
                            }}
                          >
                            {prettifyInventoryMovementType(movement.action) +
                              " #" +
                              (movement.transaction ? movement.transaction.transactionId : 0)}{" "}
                            <Icon fontSize="0.75rem" name="arrow-up-right2"></Icon>
                            <ArrowRightOutlined rotate={-45} className="ml-xs -mt-xs" />
                          </ButtonLink>
                        ) : (
                          <Text>{prettifyInventoryMovementType(movement.action)}</Text>
                        ),
                      qty: formatWithMeasurement(movement.quantity || 0),
                      location: (
                        <div>
                          <Text>{movement.locationName}</Text>
                          <Text label className="flex items-center">
                            {formatWithMeasurement(movement.oldValue || 0)}{" "}
                            <Icon name="arrow-right"></Icon>{" "}
                            {formatWithMeasurement(movement.newValue || 0)}
                          </Text>
                        </div>
                      ),
                    };
                  })
                : null,
          };
        })
      : [];

    // eslint-disable-next-line
  }, [result, measurement]);

  return !loading ? (
    <ModuleWrapper
      error={errorSupplyItem || error}
      header={
        <>
          <HeaderB
            title={name || "--"}
            description={description}
            returnText={lang.supplyItems}
            onClick={() => history.push(Path.INVENTORY_SUPPLY_ITEM)}
            button={{
              type: StyleType.Link,
              text: (
                <SimpleDropdown
                  trigger="click"
                  classname="cursor-pointer border rounded border-solid border-1 border-gray-200 flex align-center 
                         p-sm bg-white justify-center w-24 -m-md"
                  color="text-blue"
                  text={<Text className="text-blue">{lang.actions}</Text>}
                  options={[
                    {
                      value: 0,
                      text: <Text>{lang.editSupplyItem}</Text>,
                      onClick: () => {
                        history.push(Path.EDIT_SUPPLY_ITEM(id));
                      },
                    },
                    {
                      value: 1,
                      text: <Text className="text-red">{lang.deleteSupplyItem}</Text>,
                      onClick: () => {
                        deleteModal.show();
                      },
                    },
                  ]}
                ></SimpleDropdown>
              ),
            }}
          />
          <TabBar
            className="mb-lg"
            items={[
              { to: Path.SUPPLY_ITEM_ID(id), text: lang.summary },
              { to: Path.SUPPLY_ITEM_MOVEMENT_NAV(id), text: lang.inventoryMovement },
            ]}
          />
        </>
      }
    >
      <>
        <Title className="font-bold bg-white p-md rounded-t-lg">
          {result
            ? result.metadata.total +
              " " +
              pluralize(result.data.length, lang.movement, "Movements")
            : 0}
        </Title>
        <DataTable
          breakAll
          loading={loading}
          showHeader
          page={filterState.page}
          pageSize={filterState.pageSize}
          onChangePage={modifyFilters}
          fetchList={fetchSupplyItemMovement}
          total={result ? result.metadata.total : 0}
          columns={[
            {
              text: (
                <Text md className="mb-sm text-gray capitalize font-bold">
                  {lang.date}
                </Text>
              ),
              key: "date",
              headerClassName: "pl-lg",
              className: "py-4 px-4 border-t border-gray-200",
            },
            {
              text: (
                <Text md className="mb-sm text-gray capitalize pl-4 font-bold">
                  {lang.staff}
                </Text>
              ),
              key: "staff",
              className: "py-4 px-4 border-t border-gray-200",
            },
            {
              text: (
                <Text md className="mb-sm text-gray capitalize pl-4 font-bold">
                  {lang.movement}
                </Text>
              ),
              key: "movement",
              className: "py-4 px-4 border-t border-gray-200",
              custom: true,
              callback: true,
            },
            {
              text: (
                <Text md className="mb-sm text-gray capitalize pl-4 font-bold">
                  {lang.qty}
                </Text>
              ),
              key: "qty",
              className: "py-4 px-4 border-t border-gray-200",
            },
            {
              text: (
                <Text md className="mb-sm text-gray capitalize pl-4 font-bold">
                  {lang.location}
                </Text>
              ),
              key: "location",
              className: "py-4 px-4 border-t border-gray-200",
            },
          ]}
          data={renderSupplyItemMovement}
        />
        <DeleteSupplyItem id={id} {...deleteModal}></DeleteSupplyItem>
      </>
    </ModuleWrapper>
  ) : (
    <SupplyItemMovementSkeleton />
  );
};

export default SupplyItemMovement;
